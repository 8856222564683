import React, { useState, useEffect } from 'react';

import axios from 'axios';

import ContractService from '../pages/components/contract-service';

import WorkspaceMenu from '../../menus/Menu';
import icnTrash from '../../assets/icns/trash.svg';
import icnPlus from '../../assets/icns/plus.svg';
import icnGlobe from '../../assets/icns/globe-icn.svg';
import icnSaveDisk from '../../assets/icns/save-disk-icn.svg';

import deleteIcn from '../../assets/icns/trash.svg';
import editIcn from '../../assets/icns/edit.svg';

const CreateProduction = () => {



  function generateAccessKey() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 10;
    let accessKey = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      accessKey += characters[randomIndex];
    }
    return accessKey;
  }
  
  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  }
  
  const [loading, setLoading] = useState(false); 
  const [progress, setProgress] = useState(0);
  const [newDate, setNewDate] = useState('');



  


  useEffect(() => {
    setProgress(0); 
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);

          setNewDate(getCurrentDate());


          return 100;
        }
        return prev + 10; 
      });
    }, 200); 

    return () => clearInterval(interval); 
}, [loading]);





  const [productionDetails, setProductionDetails] = useState([]);

  const [newMaterial, setNewMaterial] = useState({ title: '', quantity: '', price: '', total: '', files:[], dateAdded: '' }); 
  const [productionMaterials, setProductionMaterials] = useState([]); 

  const [newRetainer, setNewRetainer] = useState({ hours: '', price: ''}); 

  

  const [newProposalNumber, setNewProposalNumber] = useState(0);
  const [productionPricing, setProductionPricing] = useState([0, 0, 0, 0]);
  const [productionTerms, setProductionTerms] = useState({paymentTerm: '100End', revisions: 0, extra: 0,});
  const [vatWindow, setVatWindow] = useState(false);
  const [vatRate, setVatRate] = useState(0.17);



  
useEffect(() => {
  runCalculations(); 

}, [vatRate]);




  const [newProduction] = useState({
    details:[],
    docs: [],
    materials: [],
    price: [],
    accesskey:'',
    currentstatus: 'proposal',
    terms:{}
  }); 




  








  const handleDetailsChange = (value, name) => {
    setProductionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

  };


  const handleNewMaterialChange = (e) => {
    const { name, value } = e.target;
    setNewMaterial((prevMaterial) => ({
      ...prevMaterial,
      [name]: value 
    }));
  };


  const [editMaterialId, setEditMaterialId] = useState(null);

  const handleEditMaterialAdded = (material) => {
    setEditMaterialId(material.id);
    setNewMaterial({
      title: material.title,
      quantity: material.quantity,
      price: material.price,
      total: material.total,
      files: [],
      dateAdded: "",
    });
  };


    const handleAddedMaterialInputChange = (e) => {
      const { name, value } = e.target;
      setNewMaterial({ ...newMaterial, [name]: value });
    };


    const handleSaveMaterialChange = (id) => {
      const updatedMaterials = productionMaterials.map((material) =>
        material.id === id ? { ...material, value: newMaterial } : material
      );
      setProductionMaterials(updatedMaterials);
      setEditMaterialId(null); 
    };


    const handleDeleteMaterial = (index) => {
      const newmaterials = productionMaterials.filter((_, i) => i !== index);
      setProductionMaterials(newmaterials);
    };

    const handleAddMaterial = () => {
    const calculatedTotal = parseFloat(newMaterial.price) * parseFloat(newMaterial.quantity);
    setProductionMaterials((prevMaterials) => [...prevMaterials, { ...newMaterial, price: parseFloat(newMaterial.price), total: calculatedTotal, files: [], dateAdded: getCurrentDate() }]);

    handlePriceUpdate(calculatedTotal);

    setNewMaterial({ title: '',  quantity: '', price: '', total: '', files: [], dateAdded:'' });
  
  };




  const handleNewRetainerChange = (e) => {
    const { name, value } = e.target;
    setNewRetainer((prevHours) => ({
      ...prevHours,
      [name]: value 
    }));
  };

  const handleAddRetainer = () => {
    const calculatedTotal = parseFloat(newRetainer.hours) * parseFloat(newRetainer.price);

    handlePriceUpdate(calculatedTotal);

  
  };



  




  const handlePriceUpdate = (price) => {

    setProductionPricing(prevPricing => {
        const updatedPricing = [...prevPricing];

        let sumPrice = 0;
        productionMaterials.forEach((material) => {
          const total = parseFloat(material.total) || 0;
          sumPrice += total;
        });

        sumPrice += price;

        updatedPricing[0] = sumPrice;
        return updatedPricing;
    });

  };




  



  const runCalculations = () => {
    const price = productionPricing[0];
    const discount = productionPricing[1];

    const discountedPrice = Math.round(price - (price * discount / 100));
    const vatAmount = Math.round(discountedPrice * vatRate);
    const totalPrice = Math.round(discountedPrice + vatAmount);

    setProductionPricing((prevPricing) => [
      prevPricing[0],  
      prevPricing[1], 
      vatAmount,     
      totalPrice    
    ]);

  };

  
  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;

    
    setProductionPricing((prevPricing) => [
      prevPricing[0],  
      discount,        
      prevPricing[2],  
      prevPricing[3]   
    ]);
  };

  
  useEffect(() => {
    runCalculations();
  }, [productionPricing[0], productionPricing[1]]); 



  const handleTermsChange = (value, name) => {
    setProductionTerms((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    };

  

  

  const handleCancel = () => {
    window.location.href = '/';
  };




  const handleUpdateProduction = async () => {

    setLoading(true);

      if (!productionDetails.type){
        productionDetails.type = "project"
      };

      productionDetails.date = newDate;
      newProduction.details = productionDetails;
      newProduction.docs = [];

      if (productionDetails.type == "retainer"){
        productionDetails.retainer = newRetainer;
      };

      newProduction.materials = productionMaterials;
      newProduction.price = productionPricing;
      newProduction.updatedPrice = [];
      newProduction.tipAdded = 0;
      newProduction.terms = productionTerms;
      newProduction.accesskey =  generateAccessKey();
      newProduction.currentstatus = 'proposal';


      handleNewProposal();

  }




  const handleNewProposal = async () => {


    
      if (newProduction){
    
        try {

          const responsePdf = await axios.post('https://eemytng63f.execute-api.eu-west-1.amazonaws.com/default/monet-new-proposal',
            {
                command: "new_production",
                userKey: "paz",
                accesskey: newProduction.accesskey,
                newProduction: newProduction,
            });

            if (responsePdf){
              
              setLoading(false);
              window.location.href = `/production/${newProduction.accesskey}`;
  
            }

                      

          } catch (error) {
            console.error(error);
          }


          }
        };





  
  




  return (

    <div className="dark-background h-100">
    <div className=''>
    <WorkspaceMenu/>
      </div>


            <div className='col p-0 content py-3 text-light'>
              

              {loading && (
                <div className="m-0 p-0" style={{ width: '100%', backgroundColor: '#000', borderRadius: '0px', opacity: 1, transition: 'backgroundColor 4s ease-in-out' }}>
                        <div className="gradient-green-background py-1"
                            style={{
                            width: `${progress}%`,
                            transition: 'width 0.2s ease-in-out'
                            }}></div>
                        </div>
                      )}

              
            <div className='row m-0 p-2'>

                    <div className='col-12 mb-2 fs-16 fw-200'>
                        <p className='d-inline-flex fw-500 capitalize'>
                          New Proposal
                        </p>

                        <div className='d-inline-flex px-3 fw-200'>

                        </div>
              
                        <div className='d-inline-flex float-end '>
                        <p onClick={handleUpdateProduction} className='fw-500 capitalize text-orange border-orange d-inline-flex px-3 py-1 border-2 rounded-4 fw-600 pointer'>
                        Create
                          </p>
                          {/* <img onClick={handleUpdateProduction} style={{ width: '35px'}} className='pointer' src={icnSaveDisk} alt="save icon"/> */}
                        </div>
                    </div>

      
                </div>    





          <div className='row m-0 p-2 '>
            <div className='col-12'>
                  <p className='fw-500 capitalize'>
                    Details
                  </p>
                  <div className='col-12 grey-background border-6 my-2 mt-4'>
                  <select
                        className='form-control text-light'
                        value={productionDetails.type}
                        name="type"
                        onChange={(e) => handleDetailsChange(e.target.value, 'type')}>
                        <option value='project'>project</option>
                        <option value='retainer'>retainer</option>
                      </select>
                    </div>
                    <div className='col-12 grey-background border-6 my-2 mt-4'>
                        <input
                          className='form-control'
                          type="text"
                          name="name"
                          placeholder='Production Name'
                          onChange={(e) => handleDetailsChange(e.target.value, 'name')}
                        />
                    </div>

                    <div className='col-12 grey-background border-6 my-2'>
                      <input
                        className='form-control text-light'
                        type="date"
                        name="date"
                        onChange={(e) => handleDetailsChange(e.target.value, 'date')}
                      />
                    </div>   

                    <div className='col-12 grey-background border-6 my-2'>
                      <input
                          className='form-control capitalize'
                          type="text"
                          name="client"
                          placeholder='client'
                          onChange={(e) => handleDetailsChange(e.target.value, 'client')}
                        />
                    </div>   

                    <div className='col-12 grey-background border-6 my-2'>

                    <input
                        className='form-control capitalize'
                        type="text"
                        name="description"
                        placeholder='description'
                        onChange={(e) => handleDetailsChange(e.target.value, 'description')}
                      />
                    </div>   

                    <div className='col-12 grey-background border-6 my-2'>

                    <select
                        className='form-control text-light'
                        name="currency"
                        onChange={(e) => handleDetailsChange(e.target.value, 'currency')}>
                        <option value='none'>Curency</option>
                        <option value='ils'>ILS</option>
                        <option value='usd'>USD</option>
                        <option value='eur'>EUR</option>
                      </select>
                    </div>   


                </div>   
            </div>    





          <div className='row m-0 p-2 '>
          { productionDetails.type != "retainer" ? (

            <div className='col-12 py-4'>
                <p className='fw-500 capitalize'>
                    Materials
                      </p>

                  <div className={`row px-2 m-0 mt-3 grey-background border-6`}>
                    <div className='col-12 py-2 fs-16 fw-200 '>
                        <div className='border-bottom-light-1px'>
                            <input
                              className='col-12 my-1 form-control border-bottom-light-1px'
                              type="text"
                              name="title"
                              value={newMaterial.title}
                              onChange={handleNewMaterialChange}
                              placeholder="title"
                              />
                            </div>

                            <div className='border-bottom-light-1px'>
                              <input
                              className='col-12 my-1 form-control '
                              type="number"
                              name="quantity"
                              value={newMaterial.quantity}
                              onChange={handleNewMaterialChange}
                              placeholder="quantity"
                              />
                            </div>

                            <div className='border-bottom-light-1px'>
                              <input
                                className='col-12 my-1 form-control'
                                type="number"
                                name="price"
                                value={newMaterial.price}
                                onChange={handleNewMaterialChange}
                                placeholder="price"
                              />
                            </div>


                            <div className='px-2 py-3'>
                                <p onClick={() => handleAddMaterial()}
                                className='fw-500 capitalize text-light pointer fs-14'>
                                  Save
                                </p>
                            </div>
                        </div>
                      </div>
     


                      <div className='col-12 fs-16 fw-200 mt-2'>
                        {productionMaterials && Array.isArray(productionMaterials) && ( <>
                          {productionMaterials.map((material, materialIndex) => (
                            <div className='col-12 px-2 py-4 fs-16 fw-200 border-bottom-light-1px' key={materialIndex}>
                              {editMaterialId === material.id ? (
                            // {!editItemId ? (

                              <div className='row editable-form grey-background p-3 rounded-2'>
                          
                              <input
                              className='col my-1'
                              type="text"
                              name="title"
                              value={newMaterial.title}
                              onChange={handleAddedMaterialInputChange}
                              placeholder="title"
                              />

                              <input
                              className='col my-1 '
                              type="number"
                              name="quantity"
                              value={newMaterial.quantity}
                              onChange={handleAddedMaterialInputChange}
                              placeholder="quantity"
                              />

                              <input
                                className='col my-1'
                                type="number"
                                name="price"
                                value={newMaterial.price}
                                onChange={handleAddedMaterialInputChange}
                                placeholder="price"
                              />
                                <div className='pointer fw-500 ' onClick={() => handleSaveMaterialChange(materialIndex)}>Save</div>
                              </div>
    
                            ) : (
                              
                              <>
                              <div className='d-inline-flex pe-2 fw-500 capitalize'>
                              <img onClick={() => handleDeleteMaterial(materialIndex)} className='d-inline-flex me-2 fw-500 capitalize' src={deleteIcn} width="15px"/>
                              <img onClick={() => handleEditMaterialAdded(materialIndex)} className='d-inline-flex me-4 fw-500 capitalize' src={editIcn} width="20px"/>

                                  <p className="fw-400">{material.title}</p>
                                </div>
                                  <div className='d-flex align-items-top float-end '>
                                    <p className="text-end"><span className='fs-12 fw-200'>q.</span> {material.quantity}</p>
                                    <p className="px-4 text-end"><span className='fs-12 fw-200'>p.</span> {material.price}</p>
                                    <p className="text-end"><span className='fs-12 fw-200'>t.</span> {material.total}</p>
                                </div>
                              </>
                            )}
                              </div>

                              ))}</>
                            )}
                      </div>
                    </div>
                  ):(
                    
                    <div className='col-12 py-4'>
                    <p className='fw-500 capitalize'>
                        Retainer Hours
                          </p>
    
                      <div className={`row px-2 m-0 mt-3 grey-background border-6`}>
                        <div className='col-12 py-2 fs-16 fw-200 d-inline-flex'>    
                                <div className='col-6'>
                                  <p className='fw-400 capitalize pb-2'>hours</p>
                                  <input
                                  className=' my-1 form-control '
                                  type="number"
                                  name="hours"
                                  value={newRetainer.hours}
                                  onChange={handleNewRetainerChange}
                                  placeholder="hours"
                                  />
                                </div>
    
                                <div className='col-6 '>
                                <p className='fw-400 capitalize pb-2'>Price Per Hour</p>

                                  <input
                                    className='my-1 form-control'
                                    type="price"
                                    name="price"
                                    value={newRetainer.price}
                                    onChange={handleNewRetainerChange}
                                    placeholder="price per hour"
                                  />
                                </div>

                            </div>
                          </div>
                          <div className='px-2 py-3'>
                                <p onClick={() => handleAddRetainer()}
                                className='fw-500 capitalize text-light pointer fs-14'>
                                  Save
                                </p>
                            </div>
  
                        </div>


                  )}
                  </div>




 



      <div className='row m-0 p-2 py-4'>
        <div className='col-6'>
          <p className='fw-500 capitalize'>
            Price
              </p>

              <div className='py-2'></div>

              <div className='col-12 d-inline-flex fs-16 fw-200 py-1 px-2'>
                <p className='fs-16 fw-600'>Price. <span className='fw-400'>{productionPricing[0]}</span> <span className='fw-200 allCaps'>{productionDetails.currency}</span>  </p>
              </div>

              <div className='col-12 d-inline-flex fs-16 fw-200 py-1 px-2'>
                <p className='fs-16 fw-600'>
                  Discount. <span className='fw-300 fs-12'>[%]</span>
                  </p>
                  <div className='d-inline-flex fs-16 fw-200'>
                      <input
                        type="number"
                        value={productionPricing[1]} 
                        onChange={handleDiscountChange}
                        placeholder="Enter discount"
                        className='form-control-xs mx-2 p-0'
                        />
                </div>
              </div>



              <div className='col-12 d-inline-flex fs-16 fw-200 py-1 px-2'>
                <p 
                className='fs-16 fw-600 pointer'
                  onClick={() => { setVatWindow(!vatWindow); }}>
                  VAT. <span className='fw-300 fs-12'>{vatRate * 100}%</span><span className='fw-400 ps-2 text-light'>{productionPricing[2]}</span><span className='fw-200 allCaps'> {productionDetails.currency}</span>
                </p>

                <div>
                    {vatWindow && (
                      <div className="grey-background border-light-1px border-6 ms-2">
                      <div className=" px-3 py-2 ">

                        <p onClick={() => { setVatRate(0.17); setVatWindow(!vatWindow);  }}  className="fw-200 my-2 pointer">Israel</p>
                        <p onClick={() => { setVatRate(0); setVatWindow(!vatWindow); }}  className="fw-200 my-2 pointer">Abroad</p>

                        </div>
                    </div>
                      )}
                </div>



              </div>

              <div className='col-12 d-inline-flex fs-16 fw-200 py-1 px-2'>
                <p className='fs-16 fw-600 text-orange '>
                  Total. <span className='fw-400'>{productionPricing[3]} <span className='fw-200 allCaps'>{productionDetails.currency}</span></span>
                </p>
              </div>




              </div>

              <div className='col-6 border-left-light-1px'>
                <p className='fw-500 capitalize'>
                  Terms
                </p>
                <div className='py-2'></div>


              <div className='col-12d-inline-flex fs-16 fw-200 grey-background border-6'>
                <select
                  className='form-control text-orange-light'
                  value={newProduction.payment_term}
                  onChange={(e) => handleTermsChange(e.target.value, 'payment_term')}>
                  <option value='100% of the total amount after approving the final draft.'>100% after final approval</option>
                  <option value='50% of the total amount in advance, and the balance 50% after approving the final draft.'>50% in advance and 50% after final approval</option>
                  <option value='100% of the total amount in advnace'>100% in advance</option>
                  <option value='100% of the total amount in advnace'>Custom</option>
                </select>
              </div>

                <div className='col-12 d-inline-flex fs-16 fw-200 mt-2 grey-background border-6'>
                  <input
                      className='form-control capitalize'
                      type="number"
                      name="revisions"
                      placeholder='revision rounds'
                      value={productionTerms[1]}
                      onChange={(e) => handleTermsChange(e.target.value, 'revisions')}
                    />
                </div>

                <div className='col-12 d-inline-flex fs-16 fw-200 mt-2 grey-background border-6'>
                  <input
                      className='form-control capitalize'
                      type="number"
                      name="extra"
                      placeholder='extra revision'
                      value={productionTerms[2]}
                      onChange={(e) => handleTermsChange(e.target.value, 'extra')}
                    />
                </div>





          </div>
        </div>










      <div className='row m-0 p-2'>
        <div className='col-12 pt-4'>
          <p className='fw-500 capitalize'>
            Work Agreement
              </p>
              {/* <ContractService production={production} contractExport={getContract} /> */}
              <ContractService production={newProduction}/>

          </div>
        </div>


        <div className='row m-0 p-2 mb-4 '>
        <div className='col-12 '>
        <p onClick={handleCancel} className='fw-500 capitalize text-grey d-inline-flex px-3 py-1 border-2 rounded-4 fw-400 pointer'>
            Cancel
              </p>
          <p onClick={handleUpdateProduction} className='mx-4 fw-500 capitalize text-orange border-orange d-inline-flex px-3 py-1 border-2 rounded-4 fw-600 pointer'>
            Create
              </p>
          </div>
        </div>


            
          </div>
          </div>

  );
};
export default CreateProduction;

