import React, {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import WorkspaceMenu from '../../menus/Menu';



const ProductionListIndex = () => {
  const [productions, setProductions] = useState([]);
  
  useEffect(() => {
    getProductions();

    
  }, []);

  const getProductions = async () => {
    try {
      const response = await axios.post('https://pfr9v0htsl.execute-api.eu-west-1.amazonaws.com/default/monet-functions',
        {
          command: "productions",
          userKey: "paz",


        });

      console.log(response.data);
      const fillteredProductions= response.data.productions.filter(production => production.currentstatus === 'production' || production.currentstatus === 'bill requested' || production.currentstatus === 'proposal approved' || production.currentstatus === 'final review' );
      setProductions(fillteredProductions);


    } catch (error) {
      console.error(error);
    }
  };



    return (

    <div className="dark-background h-100">
      <div className=''>
          <WorkspaceMenu/>
            </div>
                <div className='col content py-3'>
                  <p className='fs-14 px-3 mb-3 fw-500 capitalize text-light'>productions</p>
                    {/* {productions.sort((a, b) => {
                      const dateA = a.details.date.split('-').reverse().join('-');
                      const dateB = b.details.date.split('-').reverse().join('-');
                      return new Date(dateB) - new Date(dateA);
                      }).map(production => ( */}
                  {productions.map(production => (
                  <div className='px-3 capitalize border-top-yellow-light-1px fs-14 fw-200' key={production.id}>
                    <Link to={`/production/${production.accesskey}`}>
                    <div className='pointer my-2'>
                          <div className="d-inline-flex">
                          {production.details.type == "retainer" &&(
                            <p className='d-flex text-orange background-orange m-0 fw-500 pe-2'>
                              R
                            </p>
                          )}
                          </div>
                          <p className='d-inline-flex text-orange-light pe-3 m-0 fw-500'>
                            {production.details.name}
                          </p>
                          <p className='d-inline-flex text-light pe-3 m-0 fw-200'>
                            {production.details.description}
                          </p>
                          <p className='d-inline-flex text-light m-0 fw-200'>
                            {production.details.client}
                          </p>
                          <div className='d-flex text-light align-items-top m-0 float-end px-2'>
                            <p className='d-inline-flex text-light me-4 fw-200'>
                              {production.price[3]}
                            </p>
                            <p className='d-inline-flex text-light m-0 fw-200'>
                              {production.currentstatus}
                            </p>
                          </div>
                    </div>
                    </Link>


                  </div>
                ))}
            </div>

            </div>

  );
};


export default ProductionListIndex;

